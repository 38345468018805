import React from 'react'
import { Link } from 'gatsby'

import SEO from '../components/seo'

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" />
    <section className='banner banner--error404'>
	    <div className='banner__inner'>
	      <div className='banner__content'>
		    <h1>NOT FOUND</h1>
		    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
		    <Link className='btn' to='/'>Back home</Link>
		  </div>
		 </div>
		</section>
  </>
)

export default NotFoundPage
